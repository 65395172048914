@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap";
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  overflow-x: hidden;
}

div#main {
  width: 100vw;
  flex-direction: column;
  display: flex;
}

section {
  width: 100vw;
  position: relative;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  margin: 20px 0 0;
  font-family: Cormorant Garamond, serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: .8;
}

portfolio {
  font-family: Cormorant Garamond, serif;
}

h2 {
  margin: 0;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: .9em;
  font-weight: 300;
  line-height: 1.5;
}

p.large {
  font-size: .9em;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
  display: grid;
}

.container .box {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  display: flex;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

@media only screen and (min-width: 1024px) {
  .myname {
    font-family: Cormorant Garamond, serif;
    font-size: 5em;
    animation: fadeIn 4s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mytitle {
    font-family: Cormorant Garamond, serif;
    font-size: 2.2em;
  }

  p.large {
    justify-content: stretch;
    font-size: .9em;
  }

  ul {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .myname {
    font-family: Cormorant Garamond, serif;
    font-size: 4em;
    animation: fadeIn 4s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .about {
    font-size: 1.7em;
    display: block;
  }

  .mytitle {
    font-family: Cormorant Garamond, serif;
    font-size: 1.2em;
    display: none;
  }

  p.large {
    justify-content: stretch;
    font-size: .9em;
  }

  ul {
    font-size: .7em;
  }

  @media only screen and (max-width: 767px) {
    .myname {
      font-family: Cormorant Garamond, serif;
      font-size: 1.7em;
    }

    .about {
      font-size: 2em;
      display: block;
    }

    .mytitle {
      display: none;
    }

    p.large {
      font-size: .9em;
    }
  }

  @media only screen and (max-width: 400px) {
    .myname {
      font-family: Cormorant Garamond, serif;
      font-size: .2em;
    }

    .about {
      font-size: 1.7em;
      display: block;
    }

    .head {
      font-size: .7em;
    }

    .mytitle {
      display: none;
    }

    p.large {
      justify-content: stretch;
      font-size: .9em;
    }
  }
}

.sorry-container {
  height: 100vh;
  text-align: center;
  color: #fff;
  background-image: url("star1.2c7d0a16.gif");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sorry-text {
  font-family: Cormorant Garamond, serif;
  font-size: 1.5rem;
  animation: fadeIn 3s ease-in-out;
}

@media (max-width: 768px) {
  .sorry-text {
    font-size: 1rem;
    animation: fadeIn 2s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/*# sourceMappingURL=index.0b1cb6ba.css.map */
