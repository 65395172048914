/* src/Components/SorryPage.css */

.sorry-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url('../images/star1.gif'); /* Adjust the path as needed */
  background-size: cover;
  background-position: center;
  color: #fff;
}

.sorry-text {
  font-size: 1.5rem;
   animation: fadeIn 3s ease-in-out;
    font-family: "Cormorant Garamond", serif;
   
  } 

/* Media query for responsiveness */
@media (max-width: 768px) {
  .sorry-text {
    font-size: 1rem;
    animation: fadeIn 2s ease-in-out;

  }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
}
