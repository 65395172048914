/**
* CSS file to hold styles that apply across the application
*/

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap");

html,
body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: 0.8;
  margin: 0;
  margin-top: 20px;
}
portfolio{
  font-family: "Cormorant Garamond", serif;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  padding-top: 1rem;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 0.9em;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

p.large {
  font-size: 0.9em;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
}

.container .box {
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

/* YourReactComponent.css */

/* Base styles for all screens */

/* Responsive styles for smaller screens

/* styles for desktop devices */
@media only screen and (min-width: 1024px) {
  .myname {
    font-family: "Cormorant Garamond", serif;
    font-size: 5em;
    animation: fadeIn 4s ease-in-out;
  
  }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
  .mytitle {
    font-family: "Cormorant Garamond", serif;

    font-size: 2.2em;


  }
  p.large{
    font-size: 0.9em;
    justify-content: stretch;
  
  }
  ul{
    font-size: 1.25rem;
  }
}

/* styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .myname {
    font-family: "Cormorant Garamond", serif;
    font-size: 4em;
    animation: fadeIn 4s ease-in-out;
  
  }
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.about{
  font-size: 1.7em;
  display:block;
}
  .mytitle {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.2em;
  }

  .mytitle {
    display: none;
   }

   p.large{
    font-size: 0.9em;
    justify-content: stretch;
  
  }
  ul{

  font-size:0.7em;

    
}
  


/* styles for mobile devices */
@media only screen and (max-width: 767px) {
  .myname {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.7em;
  }
  .about{
    font-size: 2em;
    display:block;
  }

  .mytitle {
   display: none;
  }
  p.large{
    font-size: 0.9em;
  
  }
  }
  @media only screen and (max-width: 400px) {
    .myname {
      font-family: "Cormorant Garamond", serif;
      font-size: 0.2em;
    }
    .about{
      font-size: 1.7em;
      display: block;
    }
    .head{
      font-size: 0.7em;
    }
    .mytitle {
      display: none;
    }

    p.large{
      font-size: 0.9em;
      justify-content: stretch;
     
    
    }
  
  }
}